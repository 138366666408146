import { ContainedBtn } from '@components/Buttons';
import { SeoHeader } from '@components/Common';
import { GuidesAndTipsFooter } from '@components/GuidesAndTips';
import { CookiePolicy } from '@components/Layouts';
import { Footer, NavigationHeader } from '@components/Navigation';
import {
  authorHtmlParserOptions,
  getHtmlHeadersTree,
  GuidesAndTipsMerchantBigCard,
  isPostContentContainShortcode,
  PostAuthorLabel,
  PostCategoryTag,
  PostContent,
  PostHeaderElement,
  PostNutgraph,
  PostPlaceholderImage,
  PostSidebar,
  PostTableOfContentsMobileDropdown,
} from '@components/Post';
import { Resource } from '@core';
import { GuidesAndTipsDocument, sortGuidesPostCategoriesByLabel } from '@core/content';
import { getSeeMoreRelatedMerchantsLink, Routes } from '@navigation';
import { mc } from '@styles';
import { clearHtmlTagText, stripHtml, textToId } from '@utils';
import { format, parse } from 'date-fns';
import { GatsbyImage, getImage, ImageDataLike } from 'gatsby-plugin-image';
import parseHtml from 'html-react-parser';
import { isEmpty } from 'lodash';
import React, { FC, useState } from 'react';

import * as styles from './guidesPostPage.module.css';

interface PageContext {
  slug: string;
  post: GuidesAndTipsDocument;
  relatedMerchants: Resource[];
  relatedPosts: GuidesAndTipsDocument[];
}

interface Props {
  pageContext: PageContext;
}

const GuidesPostPage: FC<Props> = ({ pageContext }) => {
  const { relatedMerchants, relatedPosts, post } = pageContext;

  const html = `${post.itemContent.content.mainContent} ${post.itemContent.content.bonusContent}`;
  const contents = getHtmlHeadersTree(html);

  const image = getImage(post.headerImage as ImageDataLike);
  const title = post.itemContent.content.headerTitle;
  const [visible, setVisible] = useState<Element>();

  const handleVisibilityChange = (element: Element, inView: boolean) => {
    if (inView) {
      setVisible(element);
    }
  };

  const handleTableContentsItemClick = (itm: PostHeaderElement) => {
    const el = document.getElementById(itm.id);
    if (!el) return;
    setVisible(el);
  };

  const elementClassNames = {
    a: mc('underline font-bold text-accent-orange hover:text-accent-orange hover:underline visited:text-ocean-blue'),
    h1: mc('font-literata font-medium'),
    h2: mc('text-3xl'),
    h3: mc('text-xl font-bold font-raleway'),
    h4: mc('font-literata font-medium'),
    h5: mc('font-literata font-medium'),
    h6: mc('font-literata font-medium'),
    ul: mc('list-disc pl-10'),
    ol: mc('list-decimal pl-10'),
  };

  const { nutGraphLessmetaDesc, mainContent, authorName, aboutTheAuthor, categoryPill, headerTitle, bonusContent } =
    post.itemContent.content;
  const nutgraph = nutGraphLessmetaDesc ? clearHtmlTagText(nutGraphLessmetaDesc) : undefined;

  return (
    <>
      <NavigationHeader />

      <CookiePolicy />

      <section className="bg-light-gray">
        <div className="flex flex-col lg:flex-row items-center">
          <div className="w-full lg:w-5/12 pl-8 xl:pl-20 py-12 xl:py-20 pr-12">
            {!isEmpty(relatedMerchants) && (
              <a
                className="mb-4 font-semibold inline-block text-dark hover:text-accent-orange"
                target="_blank"
                href={Routes.affiliateDisclosure.url}
              >
                Affiliate Disclosure
              </a>
            )}
            <div className="-mx-1 -my-1">
              {sortGuidesPostCategoriesByLabel(categoryPill).map(c => (
                <PostCategoryTag containerClassName="px-1 py-1" key={c.id} title={c.label} />
              ))}
            </div>

            <h1 className={mc('mt-4 mb-0', 'text-4xl lg:text-6xl lg:leading-extra-snug tracking-little-wide')}>{headerTitle}</h1>
            <div className="flex flex-row items-center mt-4">
              <PostAuthorLabel
                className="text-dark-gray"
                author={{
                  name: authorName,
                  about: aboutTheAuthor || '',
                }}
              />

              <span className="block ml-2 pl-2 leading-4 border-l border-l-ocean-blue font-medium text-dark-gray">
                {format(parse(post.fields?.createdAt || '', 'yyyy-MM-dd HH:mm:ss', new Date()), 'MMMM dd, yyyy')}
              </span>
            </div>
          </div>
          <div className="w-full lg:w-7/12">
            {image ? <GatsbyImage className="rounded-l-lg" alt={title} image={image} /> : <PostPlaceholderImage alt={title} />}
          </div>
        </div>
      </section>

      <div className="lg:hidden mt-10">
        <h6 className="block mb-4 font-bold text-lg leading-6 px-8 font-raleway mt-0">Table of Contents</h6>

        <PostTableOfContentsMobileDropdown
          contents={contents}
          active={visible ? textToId(visible.textContent) : undefined}
          onItemClick={handleTableContentsItemClick}
        />
      </div>

      <div className="container mx-auto px-8 xl:px-20 overflow-hidden">
        <div className="flex flex-col lg:flex-row justify-between pb-16 lg:-mx-5 xl:-mx-10">
          <div className={mc('w-full lg:w-8/12', 'lg:px-5 xl:px-10')}>
            <section className="mt-10 xl:mt-20 container mx-auto xl:pr-4">
              {!!nutgraph && <PostNutgraph testId="post-nutgraph">{nutgraph}</PostNutgraph>}
              <PostContent
                className={mc('text-dark-gray', styles.content)}
                html={mainContent}
                elementClassNames={elementClassNames}
                relatedMerchants={relatedMerchants}
                onHeaderElementVisibilityChange={handleVisibilityChange}
              />
            </section>
            {!isPostContentContainShortcode(mainContent, 'add_merchants') && !!relatedMerchants && !!relatedMerchants.length && (
              <section data-testid="post-footer-merchants">
                <h2 className="mt-16 mb-2 font-raleway font-semibold text-3xl">Partners in this Article</h2>
                <div className="flex flex-col">
                  {relatedMerchants.map(itm => (
                    <GuidesAndTipsMerchantBigCard key={itm.id} className="mt-6" merchant={itm} />
                  ))}
                </div>
                <div className="flex flex-row-reverse mt-10">
                  <ContainedBtn href={getSeeMoreRelatedMerchantsLink(relatedMerchants)} color="orange" size="sm" className="px-8">
                    See more
                  </ContainedBtn>
                </div>
              </section>
            )}
            {!!bonusContent && (
              <section className="mt-20 container mx-auto">
                <PostContent
                  html={bonusContent}
                  elementClassNames={elementClassNames}
                  relatedMerchants={relatedMerchants}
                  onHeaderElementVisibilityChange={handleVisibilityChange}
                />
              </section>
            )}

            {!!aboutTheAuthor && (
              <div id="about-the-author" className="p-7 mt-20 bg-light-gray rounded-lg">
                <p className="font-sharp font-medium text-ocean-blue">
                  <strong>About the author: </strong>
                  {parseHtml(aboutTheAuthor, authorHtmlParserOptions)}
                </p>
              </div>
            )}
          </div>

          <div className="hidden lg:block w-full mt-10 xl:mt-20 lg:w-4/12 lg:px-5 xl:px-10">
            <PostSidebar
              active={visible ? textToId(visible.textContent) : undefined}
              contents={contents}
              relatedPosts={relatedPosts}
              relatedMerchants={relatedMerchants}
              onTableContentsItemClick={handleTableContentsItemClick}
            />
          </div>
        </div>
      </div>

      <GuidesAndTipsFooter type="get-started-welcome" />

      <Footer privacyBannerColor="seafoam" />
    </>
  );
};

export const Head: FC<Props> = ({ pageContext }) => {
  const {
    headerImage,
    itemContent: {
      content: { headerTitle },
      seoTagging: {
        lesstitle,
        lessmetaName_keywords,
        lessmetaName_description,
        lessmetaItemprop_description,
        lessmetaProperty_ogimageItemprop,
        lessmetaProperty_url,
        lesslinkRel_canonical,
      },
    },
  } = pageContext.post;

  const title = stripHtml(lesstitle) || headerTitle;
  const keywords = stripHtml(lessmetaName_keywords);
  const itemProp = stripHtml(lessmetaItemprop_description);
  const description = stripHtml(lessmetaName_description);
  const image = stripHtml(lessmetaProperty_ogimageItemprop) || headerImage?.url;
  const url = stripHtml(lessmetaProperty_url);
  const canonical = stripHtml(lesslinkRel_canonical);

  return (
    <SeoHeader
      canonical={canonical}
      url={url}
      type="article"
      image={image}
      itemProp={itemProp}
      description={description}
      keywords={keywords}
      title={title}
    />
  );
};

export default GuidesPostPage;

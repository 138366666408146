import React, { FC } from 'react';

export const GuidesAndTipsEmptyState: FC<{ className?: string }> = ({ className = 'py-16' }) => {
  return (
    <div className={className}>
      <p className="text-3xl font-literata font-medium text-center text-ocean-blue">No results found</p>
    </div>
  );
};

export default GuidesAndTipsEmptyState;

import { GetStartedBanner, GetStartedBannerType } from '@components/Layouts';
import React, { FC } from 'react';

import GuidesAndTipsFooterFloatingCategories from './components/FloatingCategories';

interface Props {
  type: GetStartedBannerType;
}

export const GuidesAndTipsFooter: FC<Props> = ({ type = 'start-sharing' }) => {
  return (
    <>
      <div className="container mx-auto px-6 md:px-20">
        <div className="mt-32 flex flex-col justify-center items-center">
          <h2 className="mt-0 xl:w-1/2 text-center mx-auto">Explore resources that empower your care</h2>
          <GuidesAndTipsFooterFloatingCategories className="w-full" />
        </div>
      </div>
      <div className="mt-32">
        <GetStartedBanner type={type} />
      </div>
    </>
  );
};

export default GuidesAndTipsFooter;

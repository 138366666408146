import './styles.css';

import { featureFlags } from '@core/consts';
import { merchantCategoryRoutes } from '@navigation';
import { mc, StyleProps } from '@styles';
import { scrollToTop } from '@utils';
import { compact } from 'lodash';
import React, { FC, ReactNode } from 'react';

import CareSupportIcon from './assets/care-support-ic.inline.svg';
import FinanceIcon from './assets/finance-ic.inline.svg';
import HealthIcon from './assets/health-ic.inline.svg';
import HousingIcon from './assets/housing-ic.inline.svg';
import LegalIcon from './assets/legal-ic.inline.svg';

interface Category {
  title: string;
  icon: ReactNode;
  url: string;
}

const categories: Category[] = compact([
  { title: 'Care Support', icon: <CareSupportIcon />, url: merchantCategoryRoutes.careSupport.url },
  featureFlags.financeMegaCategoryEnabled && { title: 'Finance', icon: <FinanceIcon />, url: merchantCategoryRoutes.finance.url },
  { title: 'Health', icon: <HealthIcon />, url: merchantCategoryRoutes.health.url },
  { title: 'Housing', icon: <HousingIcon />, url: merchantCategoryRoutes.housing.url },
  { title: 'Legal', icon: <LegalIcon />, url: merchantCategoryRoutes.legal.url },
]);

type Props = StyleProps;

export const GuidesAndTipsFooterFloatingCategories: FC<Props> = ({ className = 'absolute', style = { bottom: -68 } }) => {
  return (
    <div
      className={mc('w-full mx-auto px-2 md:px-8 xl:px-0 floating-categories', 'flex items-center justify-center', className)}
      style={style}
    >
      <div className="flex items-center relative">
        <ul
          className={mc(
            'floating-list bg-white rounded-lg',
            'flex flex-row justify-center md:justify-start md:items-center flex-wrap md:flex-nowrap',
          )}
        >
          {categories.map((c, i) => (
            <li
              key={c.title}
              className={`relative my-3 md:my-3 xl:my-0 flex floating-category-item md:items-center justify-center  px-2 md:px-6 ${
                i === categories.length - 1 ? '' : 'md:bordered-category'
              }`}
            >
              <a
                href={c.url}
                className={mc(
                  'w-20 h-20 md:w-24 md:h-24 lg:w-32 lg:h-32 xl:w-40 xl:h-40',
                  'flex flex-col items-center justify-center',
                  'text-ocean-blue stroke-ocean-blue hover:stroke-accent-orange hover:text-accent-orange',
                )}
                onClick={scrollToTop}
              >
                {c.icon}
                <span className="text-center font-bold text-sm md:text-md">{c.title}</span>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default GuidesAndTipsFooterFloatingCategories;
